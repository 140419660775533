<template>
  <base-section
    :title="$t('user_management.section.budgets.title')"
  >
    <el-row ref="budgets" :key="key" v-loading="loading">
      <el-col>
        <p class="description">
          {{$t('company_settings.available_budgets_page.company_budgets_section.description')}}
        </p>
      </el-col>
      <template v-for="(type, index) in types">
        <el-col :span="24" :key="index + 'checkbox'" style="max-width: 700px">
          <base-checkbox
            :label="type.name"
            :value="selectedBudgets[type.budgetType]"
            @input="(v) => change(type.budgetType, v)"
            :explanation="type.info"
          />
        </el-col>
      </template>
      <p v-if="$v.form.$invalid && $v.form.$dirty" class="error validation-error">
        {{$t('validations.required.with_label', {label: 'Budget'})}}
      </p>
    </el-row>
  </base-section>
</template>
<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { keys, zipObject } from 'lodash'
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyBudgetsSection',
  data () {
    return {
      loading: false,
      key: ''
    }
  },
  props: {
    value: {
      type: Array
    }
  },
  validations: {
    form: {
      required
    }
  },
  computed: {
    ...mapGetters({
      corporateAccount: 'corporateAccount/selectedCorporateAccount'
    }),
    budgetTypes () {
      return this.corporateAccount.budgetTypes
    },
    allowedBudgetTypes () {
      return this.corporateAccount.allowedBudgetTypes
    },
    selectedBudgets: {
      get () {
        const budgetTypes = this.value || []
        return zipObject(budgetTypes, new Array(budgetTypes.length).fill(true))
      }
    },
    form () {
      return keys(this.selectedBudgets).filter(v => this.selectedBudgets[v]).join(',')
    },
    types () {
      return this.budgetTypes.filter(v => this.allowedBudgetTypes.includes(v.budgetType)) || []
    }
  },
  methods: {
    change (target, value) {
      Vue.set(this.selectedBudgets, target, value)
      this.$emit('input', keys(this.selectedBudgets).filter(v => this.selectedBudgets[v]))
      this.key = this.$key()
    }
  }
}
</script>

<style scoped>
p.description {
  margin-top: 0;
}
</style>
