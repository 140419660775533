<template>
  <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           v-loading="loading">
    <company-budgets-section
      v-model="form.selectedBudgetTypes"
      id="companyBudgetsSection"
      ref="companyBudgetsSection"
    ></company-budgets-section>
    <company-usage-reason-section
      ref="companyUsageReasonSection"
      id="companyUsageReasonSection"
      v-model="form.corporateAccountData"
    ></company-usage-reason-section>
  </el-form>
</template>

<script>
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'
import CompanyBudgetsSection from '@/components/sections/company/CompanyBudgetsSection'
import CompanyUsageReasonSection from '@/components/sections/company/CompanyUsageReasonSection'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

export default {
  name: 'CompanySettingsBudgets',
  components: {
    CompanyBudgetsSection,
    CompanyUsageReasonSection
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      form: {
        selectedBudgetTypes: []
      },
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId'
    })
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.dirty = true
      }
    }
  },
  methods: {
    ...mapActions({
      getData: 'corporateAccount/getData',
      selectCorporateAccountOspId: 'corporateAccount/selectCorporateAccountOspId'
    }),
    fetchData () {
      this.loading = true
      return corporateAccountRepository.getData(this.corporateAccountOspId, {
        onSuccess: this.setData,
        onFinally: () => {
          this.loading = false
          this.key = this.$key()
        }
      })
    },
    handleSaveChanges () {
      this.$refs.companyBudgetsSection.$v.$touch()
      if (this.$refs.companyBudgetsSection.$v.$invalid) {
        this.$scrollTo('#companyBudgetsSection')
        return false
      }
      this.saving = true
      this.$api.corporateAccount.update(
        this.form, {
          successNotification: this.$t('company_settings.available_budgets_page.success_notification'),
          onSuccess: () => {
            this.dirty = false
            if (
              this.$auth.corporateAccountId() === this.corporateAccountOspId
            ) {
              this.getData()
            }
            this.$auth.user().isMsp && this.selectCorporateAccountOspId(this.corporateAccountOspId)
          },
          onFinally: () => {
            this.saving = false
          }
        }).catch((e) => {
        console.log(e)
        this.saving = false
      })
    },
    setData (response) {
      this.form = {
        ...response,
        ospId: response.corporateAccount.ospId,
        corporateAccountData: response.corporateAccount.data
      }
      this.saveDisabled = false
      this.key = this.$key()
      setTimeout(() => {
        this.dirty = false
      }, 100)
    }
  },
  async mounted () {
    await this.fetchData()
  }
}
</script>

<style scoped>

</style>
