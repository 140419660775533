<template>
  <el-form ref="form" :model="form" @submit.prevent.native="null" :key="key" autocomplete="off" id="appUserForm"
           v-loading="loading">
    <el-alert
      type="info"
      :closable="false"
      show-icon>
      <span v-html="$t('company_settings.available_products_page.alert')">
      </span>
    </el-alert>
    <base-section
      :title="$t('products_management.title')"
      :is-flex="true"
      v-loading="loading"
    >
      <base-transfer
        :top-titles="[$t('corporate_accounts_management.add_page.set_products.transfer.left_panel_title'), $t('corporate_accounts_management.add_page.set_products.transfer.right_panel_title')]"
        filterable
        :titles="[$t('general.label.select_all'), $t('general.label.select_all')]"
        v-model="form.selectedProducts"
        :height="470"
        :full-width="true"
        :with-pagination="true"
        :data="products">
      </base-transfer>
    </base-section>
  </el-form>
</template>

<script>
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { mapActions, mapGetters } from 'vuex'
import { sortBy } from 'lodash'
import CorporateAccountRepository from '@/app/repositories/CorporateAccountRepository'

const corporateAccountRepository = new CorporateAccountRepository()

export default {
  name: 'CompanySettingsProducts',
  components: {
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      form: {
        selectedProducts: []
      },
      products: [],
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId'
    })
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.dirty = true
      }
    }
  },
  methods: {
    ...mapActions({
      getData: 'corporateAccount/getData',
      selectCorporateAccountOspId: 'corporateAccount/selectCorporateAccountOspId'
    }),
    fetchData () {
      return corporateAccountRepository.getData(this.corporateAccountOspId, {
        onSuccess: this.setData,
        onFinally: () => {
          this.key = this.$key()
        }
      })
    },
    fetchProducts () {
      return this.$api.product.findAll({
        onSuccess: r => {
          this.products = sortBy(r.map(p => {
            return {
              key: p.ospId,
              label: this.$trans(p.productLabel)
            }
          }), p => p.label)
          // this.key = this.$
        }
      })
    },
    handleSaveChanges () {
      this.saving = true
      return this.$api.corporateAccount.update(
        this.form, {
          successNotification: this.$t('company_settings.available_budgets_page.success_notification'),
          onSuccess: () => {
            this.dirty = false
            this.getData()
            this.selectCorporateAccountOspId(this.form.ospId)
          },
          onFinally: () => {
            this.saving = false
          }
        }).catch((e) => {
        console.log(e)
        this.saving = false
      })
    },
    setData (response) {
      this.form = {
        ...response,
        ospId: response.corporateAccount.ospId,
        corporateAccountData: response.corporateAccount.data,
        selectedProducts: response.selectedProducts.map(p => p.ospId)
      }
      this.saveDisabled = false
      this.key = this.$key()
      setTimeout(() => {
        this.dirty = false
      }, 100)
    }
  },
  async mounted () {
    this.loading = true
    await Promise.all([
      this.fetchProducts(),
      this.fetchData()
    ])
    this.loading = false
  }
}
</script>
