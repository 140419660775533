var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],key:_vm.key,ref:"form",attrs:{"model":_vm.model,"autocomplete":"off","id":"appUserForm","label-position":"top"},nativeOn:{"submit":function($event){$event.preventDefault();return null.apply(null, arguments)}}},[_c('el-alert',{attrs:{"type":"info","closable":false,"show-icon":""}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.$t('partners_management.view_partner.branding.alert_text'))}})]),_c('base-section',{attrs:{"title":_vm.$t('partners_management.view_partner.branding.images_section.title')}},[_vm._l((_vm.imageTypesList),function(imageTypes,idx){return [_c('el-row',{key:idx,style:({
          'margin-bottom': idx === _vm.imageTypesList.length - 1 ? 0 : '32px'
        }),attrs:{"gutter":24}},[_vm._l((imageTypes),function(image,index){return [_c('el-col',{key:index + 'imagetype',attrs:{"span":12}},[_c('base-branding-image',{attrs:{"accept":image.accept,"title":image.title,"subtitle":_vm.$t(
                  'partners_management.view_partner.branding.images_section.subtitle_' +
                    image.type.imageType.toLowerCase()
                )},on:{"download":function($event){return _vm.downloadImage(image)}},model:{value:(image.file),callback:function ($$v) {_vm.$set(image, "file", $$v)},expression:"image.file"}})],1)]})],2)]})],2),_c('base-section',{key:_vm.colorKey,attrs:{"title":_vm.$t('partners_management.view_partner.branding.colors_section.title'),"withBorder":false}},[_c('base-radio',{attrs:{"label":"0","value":_vm.brandingColor},on:{"input":_vm.change}},[_vm._v(" "+_vm._s(_vm.$t( 'partners_management.view_partner.branding.colors_section.radio_label.use_default' ))+" ")]),_c('base-radio',{attrs:{"label":"1","value":_vm.brandingColor},on:{"input":_vm.change}},[_vm._v(" "+_vm._s(_vm.$t( 'partners_management.view_partner.branding.colors_section.radio_label.use_custom' ))+" ")]),(_vm.brandingColor === '1')?[_c('div',{staticClass:"color-picker-group"},[_c('base-color-picker',{attrs:{"value":_vm.branding.mainPrimaryColor.value,"label":_vm.$t('partners_management.view_partner.branding.colors_section.primary_color'),"show-reset":_vm.branding.showResetMainPrimaryColor,"show-clear":false},on:{"reset":function($event){return _vm.branding.resetMainPrimaryColor()},"input":function($event){return _vm.branding.setItem(
              _vm.branding.mainPrimaryColor.significance,
              _vm.branding.mainPrimaryColor.key,
              $event
            )}}}),_vm._l((_vm.branding.primaryColorVariants),function(color){return [_c('base-color-picker',{key:color.key + color.significance,attrs:{"value":color.value,"label":_vm.getColorVariantLabel(color.key)},on:{"input":function($event){return _vm.branding.setItem(color.significance, color.key, $event)}}})]})],2),_c('div',{staticClass:"color-picker-group"},[_c('base-color-picker',{attrs:{"value":_vm.branding.mainSecondaryColor.value,"label":_vm.$t('partners_management.view_partner.branding.colors_section.secondary_color'),"show-reset":_vm.branding.showResetMainSecondaryColor,"show-clear":false},on:{"reset":function($event){return _vm.branding.resetMainSecondaryColor()},"input":function($event){return _vm.branding.setItem(
              _vm.branding.mainSecondaryColor.significance,
              _vm.branding.mainSecondaryColor.key,
              $event
            )}}}),_vm._l((_vm.branding.secondaryColorVariants),function(color){return [_c('base-color-picker',{key:color.key + color.significance,attrs:{"value":color.value,"label":_vm.getColorVariantLabel(color.key)},on:{"input":function($event){return _vm.branding.setItem(color.significance, color.key, $event)}}})]})],2)]:_vm._e()],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }