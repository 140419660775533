<template>
  <page-layout
    :title="$t('company_settings.title')"
    :sub-title="subtitle"
    :has-back-button="true"
    :emit-back="true"
    @back="back"
  >
    <template #header-actions>
      <base-button
        v-if="showSaveChanges"
        :key="key"
        :label="$t('general.button_label.save_changes')"
        type="primary"
        :loading="$refs.routerView.saving"
        :disabled="$refs.routerView.saveDisabled"
        @click="handleSaveChanges"
      />
    </template>
    <el-tabs :value="activeName" ref="tabs">
      <el-tab-pane v-for="tab in tabs" :key="tab.name" :name="tab.name">
        <span slot="label" class="flex-span"><i v-if="tab.icon" :class="tab.icon"></i> {{ tab.label }}</span>
      </el-tab-pane>
    </el-tabs>
    <router-view ref="routerView"/>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import TabNavigation from '../mixins/TabNavigation'
import { get } from 'lodash'

export default {
  name: 'CompanySettingsLayout',
  components: {
    PageLayout
  },
  mixins: [TabNavigation],
  props: {
    emitBack: {
      typ: Boolean,
      default: false
    }
  },
  data () {
    return {
      key: '',
      activeName: '',
      showSaveChanges: false
    }
  },
  watch: {
    '$route.name' () {
      setTimeout(() => {
        this.key = this.$key()
      }, 50)
    }
  },
  methods: {
    handleSaveChanges () {
      return this.$refs.routerView.handleSaveChanges()
    },
    showButtons () {
      this.showSaveChanges = !!this.$route.meta.showSaveChangesButton
    }
  },
  computed: {
    tabs () {
      const tabs = [
        {
          label: this.$t('company_settings.details_page.title'),
          name: 'CompanySettingsDetails',
          icon: 'ri-building-4-line'
        },
        {
          label: this.$t('company_settings.available_budgets_page.title'),
          name: 'CompanySettingsBudgets',
          icon: 'ri-money-euro-circle-line'
        }
      ]
      if (this.$auth.user().isMsp) {
        tabs.push({
          label: this.$t('company_settings.app_menu_page.title'),
          name: 'CompanySettingsAppMenu',
          icon: 'ri-menu-line'
        })
        tabs.push({
          label: this.$t('company_settings.available_products_page.title'),
          name: 'CompanySettingsProducts',
          icon: 'ri-ticket-line'
        })
      }
      if (this.$auth.user().isOspAdmin) {
        tabs.push({
          label: 'CompanyBranding',
          name: 'CompanyViewBranding',
          icon: 'ri-pencil-line'
        })
      }
      return tabs
    },
    subtitle () {
      const currentTab = this.tabs.find(v => v.name === this.$route.name)
      return get(currentTab, 'label', '')
    }
  }
}
</script>
