<template>
  <base-section
    :title="$t('company_settings.details_page.security_section.title')"
    :key="key"
  >
    <el-row :gutter="24">
      <el-col :span="12">
        <base-checkbox
          :label="$t('general.label.enable_two_factor')"
          v-model="form.requires2fa"
          style="margin-top: -10px;"
        />
      </el-col>
      <el-col :span="12" v-role="['ospadmin']">
        <base-input
          :label="$t('general.label.max_users')"
          ref="maxUsers"
          prop="maxUsers"
          v-model="form.maxUsers"
          name="maxUsers"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <base-checkbox
          :label="$t('company_settings.details_page.security_section.external_reference_mandatory.label')"
          :explanation="$t('company_settings.details_page.security_section.external_reference_mandatory.explanation')"
          v-model="form.externalReferenceMandatory"
          style="margin-top: -10px;"
        />
      </el-col>
    </el-row>
  </base-section>
</template>
<script>

export default {
  name: 'CompanySecuritySection',
  data () {
    return {
      key: ''
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  }
}
</script>

<style scoped>

</style>
