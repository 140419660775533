<template>
  <base-section :title="$t('user_management.section.account.title')" :key="key">
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.input_label.name')"
          ref="name"
          prop="name"
          :error="error"
          v-model="form.name"
          name="name"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="12">
        <el-form-item :label="$t('general.label.status')" label-position="top">
          <div class="el-input base-empty-input">
            <template v-if="form.active === true">
              <div class="label">
                <i class="ri-user-follow-line active"></i><span>{{ $t('general.active') }}</span>
              </div>
              <div class="btn-group">
                <base-button
                  :label="$t('company_settings.deactivation_page.button_request_deactivation')"
                  size="small"
                  type="danger"
                  @click="$emit('deactivate')"
                />
              </div>
            </template>
            <template v-else-if="form.active === false">
              <div class="label">
                <i class="ri-user-unfollow-line inactive"></i
                ><span>{{ $t('general.not_active') }}</span>
              </div>
              <div class="btn-group">
                <base-button
                  :label="$t('general.button_label.activate')"
                  type="success"
                  size="small"
                  @click="$emit('activate')"
                />
              </div>
            </template>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
    <el-row :gutter="24">
      <el-col :span="12">
        <base-input
          :label="$t('general.label.partner')"
          ref="partner"
          prop="partner"
          :readonly="true"
          :error="error"
          v-model="form.partner.ospId"
          name="partner"
          :disabled="!partnerEditable"
          @keyupEnter="$emit('submit')"
        />
      </el-col>
      <el-col :span="12" v-if="form.active">
        <el-form-item :label="$t('general.label.active_since')" label-position="top">
          <div class="el-input base-empty-input">
            <div class="label">
              <span>{{ $dateFormat(form.startActivationTime) }}</span>
            </div>
          </div>
        </el-form-item>
      </el-col>

      <el-col :span="12" v-if="form.contractId || contractNumberEditable">
        <base-input
          :label="$t('general.label.contract_number')"
          ref="contract_number"
          prop="contract_number"
          :error="error"
          v-model="form.contractId"
          name="contract_number"
          :disabled="!contractNumberEditable"
          @keyupEnter="$emit('submit')"
        />
      </el-col>

      <el-col :span="12" v-if="form.companyType">
        <el-form-item
          :label="$t('company_settings.details_page.company_type.label')"
          label-position="top"
        >
          <div class="el-input base-empty-input">
            <div class="label">
              <span>{{ companyType }}</span>
            </div>
          </div>
        </el-form-item>
      </el-col>
    </el-row>
  </base-section>
</template>

<script>
import Role from '@/app/services/RolesService/Role'

export default {
  name: 'CompanyAccountSection',
  data () {
    return {
      key: '',
      error: ''
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    },
    contractNumberEditable () {
      // todo this field should only be editable if a user manager is an MSP or osp_admin
      return this.$auth.isMsp()
    },
    partnerEditable () {
      // todo  partner should be greyed out for regular CA user managers
      return this.$auth.hasRole(Role.OSPADMIN)
    },
    companyType () {
      if (this.form.companyType) {
        const locoKey = `company_settings.details_page.company_type.${this.form.companyType.toLowerCase()}`
        return this.$te(locoKey) ? this.$t(locoKey) : this.form.companyType
      }
      return ''
    }
  }
}
</script>
