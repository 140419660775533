<template>
  <el-form
    ref="form"
    :model="form"
    @submit.prevent.native="handleSaveChanges"
    :key="key"
    autocomplete="off"
    id="appUserForm"
    v-loading="loading"
  >
    <company-account-section
      v-model="form.corporateAccountData"
      id="companyAccountSection"
      ref="companyAccountSection"
      @deactivate="deactivate"
      @activate="activate"
      @submit="handleSaveChanges"
    ></company-account-section>
    <company-address-section
      id="companyAddressSection"
      v-model="form.corporateAccountData"
      ref="companyAddressSection"
      @submit="handleSaveChanges"
    ></company-address-section>
    <company-legal-section
      id="companyLegalSection"
      v-model="form.corporateAccountData"
      ref="companyLegalSection"
      @submit="handleSaveChanges"
    ></company-legal-section>
    <company-financial-section
      id="companyFinancialSection"
      v-model="form.corporateAccountData"
      ref="companyFinancialSection"
      @submit="handleSaveChanges"
      :partnerData="partnerData"
      :ospId="corporateAccountOspId"
    ></company-financial-section>
    <company-security-section
      id="companySecuritySection"
      v-model="form.corporateAccountData"
      ref="companySecuritySection"
      @submit="handleSaveChanges"
    ></company-security-section>
    <company-gas-stations-section
      v-if="showGasStations"
      id="CompanyGasStationsSection"
      ref="CompanyGasStationsSection"
      :ospId="corporateAccountOspId"
    ></company-gas-stations-section>
  </el-form>
</template>

<script>
import ConfirmBeforeRouterLeave from '@/mixins/ConfirmBeforeRouterLeave'
import { confirmDefaultOptions } from '@/app/helpers'
import CompanyAccountSection from '@/components/sections/company/CompanyAccountSection'
import CompanyAddressSection from '@/components/sections/company/CompanyAddressSection'
import CompanyLegalSection from '@/components/sections/company/CompanyLegalSection'
import CompanyFinancialSection from '@/components/sections/company/CompanyFinancialSection'
import CompanySecuritySection from '@/components/sections/company/CompanySecuritySection'
import CompanyGasStationsSection from '@/components/sections/company/CompanyGasStationsSection.vue'
import { mapActions, mapGetters } from 'vuex'
import PartnerRepository from '@/app/repositories/PartnerRepository'

const partnerRepository = new PartnerRepository()

export default {
  name: 'CompanySettingsDetails',
  components: {
    CompanyAccountSection,
    CompanyAddressSection,
    CompanyLegalSection,
    CompanyFinancialSection,
    CompanySecuritySection,
    CompanyGasStationsSection
  },
  mixins: [ConfirmBeforeRouterLeave],
  data () {
    return {
      form: {
        corporateAccountData: {
          partner: { ospId: '' }
        }
      },
      partnerData: {},
      key: '',
      loading: false,
      saving: false,
      saveDisabled: true,
      dirty: false
    }
  },
  computed: {
    ...mapGetters({
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId',
      activeMRPPartners: 'fuel/partners'
    }),
    partnerOspId () {
      return this.form.corporateAccountData.partner.ospId
    },
    showGasStations () {
      return (
        this.$auth.isOspAdmin() &&
        this.activeMRPPartners.some(el => el.ospId === this.partnerOspId)
      )
    }
  },
  methods: {
    ...mapActions({
      getData: 'corporateAccount/getData',
      getPartners: 'fuel/getPartners',
      selectCorporateAccountOspId: 'corporateAccount/selectCorporateAccountOspId'
    }),
    async activate () {
      try {
        await this.$confirm(
          this.$t('company_settings.activate_account.confirmation.text'),
          confirmDefaultOptions
        )
        this.loading = true
        await this.$api.corporateAccount.activate({
          ospId: this.corporateAccountOspId
        })
        await this.fetchData()
      } catch (e) {
        console.log(e)
      }
      this.loading = false
    },
    async deactivate () {
      this.$router.push({
        name: 'CompanySettingsDeactivation'
      })
    },
    fetchData () {
      this.loading = true
      return this.$api.corporateAccount.getData(this.corporateAccountOspId, {
        onSuccess: response => {
          this.setData(response)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    handleSaveChanges () {
      if (!this.validate()) {
        return false
      }
      this.saving = true
      this.$api.corporateAccount
        .update(this.form, {
          successNotification: this.$t(
            'company_settings.details_page.success_notification'
          ),
          onSuccess: () => {
            if (
              this.$auth.corporateAccountId() === this.corporateAccountOspId
            ) {
              this.getData()
            }
            this.$auth.user().isMsp && this.selectCorporateAccountOspId(this.corporateAccountOspId)
          },
          onFinally: () => {
            this.saving = false
            this.dirty = false
          }
        })
        .catch(e => {
          console.log(e)
          this.dirty = false
          this.saving = false
        })
    },
    validate () {
      const { companyAddressSection } = this.$refs
      companyAddressSection.$v.form.$touch()
      if (companyAddressSection.$v.form.$invalid) {
        this.$scrollTo('#companyAddressSection')
        return false
      }
      return true
    },
    setData (response) {
      this.form = {
        ...response,
        ospId: response.corporateAccount.ospId,
        corporateAccountData: response.corporateAccount.data
      }
      this.saveDisabled = false
      this.key = this.$key()
      setTimeout(() => {
        this.dirty = false
      }, 100)
    },
    async fetchPartnerData () {
      try {
        await partnerRepository.get(this.partnerOspId, {
          onSuccess: res => (this.partnerData = res.partner.data)
        })
      } catch (e) {
        console.error(e)
      }
    }
  },
  async mounted () {
    await this.fetchData()
    this.fetchPartnerData()
    this.getPartners()
    this.$watch('form', {
      deep: true,
      handler () {
        this.dirty = true
      }
    })
  }
}
</script>

<style scoped></style>
