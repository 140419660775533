<template>
  <page-layout
    :title="$t('company_settings.deactivation_page.title')"
    :has-back-button="true"
    id="companyDeactivationPage"
    v-loading="loading"
  >
    <template #header-actions>
      <base-button
        :label="$t('company_settings.deactivation_page.button_request_deactivation')"
        type="danger"
        :loading="loading"
        :disabled="isPrepaid && !hasIBAN"
        @click="confirmDeactivation"
      >
      </base-button>
    </template>
    <base-alert v-if="paymentType" type="warning" :closable="false" show-icon>
      <span
        v-html="$t('company_settings.deactivation_page.alert_html_' + paymentType.toLowerCase())"
      ></span>
    </base-alert>
    <el-form v-if="isPrepaid && !hasIBAN" label-position="top" :model="form" :key="key">
      <base-section :title="$t('company_settings.deactivation_page.section_title')">
        <p v-html="$t('company_settings.deactivation_page.section_explanation')"></p>
        <el-row :gutter="24">
          <el-col :span="12">
            <base-input
              :label="$t('general.input_label.iban')"
              ref="iban"
              prop="iban"
              v-model="form.iban"
              :validations="$v.form.iban"
              v-mask="bankAccountMask"
              name="iban"
            />
            <base-button
              :label="$t('general.label.save')"
              type="primary"
              :loading="loading"
              @click="updateCARequest"
            />
          </el-col>
        </el-row>
      </base-section>
    </el-form>
  </page-layout>
</template>

<script>
import PageLayout from '../layouts/PageLayout'
import { confirmDefaultOptions } from '@/app/helpers'
import { mapActions, mapGetters } from 'vuex'
import { required } from 'vuelidate/lib/validators'
import { get } from 'lodash'
import Api from '@/app/services/ApiService/Api'

export default {
  name: 'CompanySettingsDeactivation',
  components: { PageLayout },
  data () {
    return {
      loading: false,
      form: {
        iban: ''
      },
      ospId: '',
      corporateAccountData: {
        iban: ''
      },
      key: '',
      bankAccountMask: {
        mask: '#### #### #### ####',
        tokens: {
          '#': { pattern: /[a-zA-Z0-9]/, transform: v => v.toUpperCase() }
        }
      }
    }
  },
  validations: {
    form: {
      iban: {
        required
      }
    }
  },
  computed: {
    ...mapGetters({
      corporateAccountOspId: 'corporateAccount/selectedCorporateAccountOspId'
    }),
    isPrepaid () {
      return get(this.corporateAccountData, 'paymentType') === 'PREPAID'
    },
    paymentType () {
      return get(this.corporateAccountData, 'paymentType', '')
    },
    hasIBAN () {
      return !!get(this.corporateAccountData, 'iban')
    }
  },
  methods: {
    ...mapActions({
      getData: 'corporateAccount/getData'
    }),
    fetchData () {
      this.loading = true
      return this.$api.corporateAccount.getData(this.corporateAccountOspId, {
        onSuccess: response => {
          this.setData(response)
        },
        onFinally: () => {
          this.loading = false
        }
      })
    },
    setData (response) {
      this.ospId = response.corporateAccount.ospId
      this.corporateAccountData = response.corporateAccount.data
      this.form.iban = response.corporateAccount.data.iban || ''
      this.key = this.$key()
    },
    updateCARequest () {
      this.$v.form.$touch()
      if (this.$v.form.$invalid) {
        return false
      }
      this.loading = true

      return this.$api.corporateAccount.update(
        {
          corporateAccountData: {
            ...this.corporateAccountData,
            ...this.form
          },
          ospId: {
            ospId: this.ospId
          }
        },
        {
          onSuccess: () => this.fetchData(),
          onFinally: () => {
            this.loading = false
          },
          successNotification: this.$t('company_settings.details_page.success_notification')
        }
      )
    },
    deactivateCARequest () {
      this.loading = true
      return this.$api.corporateAccount.deactivate(
        { ospId: this.ospId },
        {
          onSuccess: () => {
            if (this.$auth.corporateAccountId() === this.corporateAccountOspId) {
              this.getData()
            }
            const notificationOptions = {
              message: this.$t('company_settings.deactivation_page.success_notification'),
              duration: 30 * 1000,
              type: 'success',
              dangerouslyUseHTMLString: true
            }
            Api.pushMessage(notificationOptions)
            this.$router.back()
          },
          onFinally: () => {
            this.loading = false
          }
        }
      )
    },
    confirmDeactivation () {
      this.$confirm(
        this.$t('company_settings.deactivation_page.confirmation.text'),
        this.$t('company_settings.deactivation_page.confirmation.title'),
        {
          ...confirmDefaultOptions,
          confirmButtonText: this.$t(
            'company_settings.deactivation_page.confirmation.button_confirm'
          ),
          confirmButtonClass: 'base-button el-button--danger'
        }
      ).then(() => this.deactivateCARequest())
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>
