<template>
  <base-section
    :title="$t('company_settings.section.usage_reasons.title')"
    v-loading="loading"
    id="companyUsageReasonSection"
  >
    <p class="label">{{ $t('company_settings.section.usage_reasons.checkbox_label') }}</p>
    <p class="sublabel"  style="max-width: 700px">{{ $t('company_settings.section.usage_reasons.checkbox_sublabel') }}</p>
    <div v-if="form && form.usageReasons" ref="usageReasons" :key="'usageReasons' + key" class="checkbox-group">
    <base-checkbox
        v-for="({value, label}) in usageReasons"
        :key="'checkbox_' + value"
        :label="label"
        :value="form.usageReasons.indexOf(value) !== -1"
        @input="(v) => change(value, v)"
      ></base-checkbox>
    </div>
    <p class="label">{{ $t('company_settings.section.usage_reasons.radio_label') }}</p>
    <div  v-if="form && form.defaultUsageReason !== undefined" ref="usageReasonDefault" :key="'usageReasonDefault' + key"  class="radio-group">
      <base-radio
        v-for="({value, label}) in usageReasons"
        :key="'radio_' + value"
        :label="value"
        :value="form.defaultUsageReason"
        @input="changeDefault"
      >
        {{ label }}
      </base-radio>
    </div>
  </base-section>
</template>
<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'CompanyUsageReasonSection',
  data () {
    return {
      loading: false,
      key: '',
      usageReasons: [
        {
          value: 'BUSINESS',
          label: 'Business'
        },
        {
          value: 'PRIVATE',
          label: 'Private'
        },
        {
          value: 'COMMUTING',
          label: 'Commuting'
        }
      ]
    }
  },
  props: {
    value: {
      type: Object
    }
  },
  watch: {
    form: {
      deep: true,
      handler () {
        this.key = this.$key()
      }
    }
  },
  validations: {
    form: {
      required
    }
  },
  computed: {
    form: {
      get () {
        return this.value
      },
      set (v) {
        this.$emit('input', v)
      }
    }
  },
  methods: {
    change (target, value) {
      const index = this.form.usageReasons.indexOf(target)
      if (value && index === -1) {
        this.form.usageReasons.push(target)
      } else if (!value && index !== -1) {
        this.form.usageReasons = this.form.usageReasons.filter(v => v !== target)
      }
      // Vue.set(this.selectedBudgets, target, value)
      // this.$emit('input', keys(this.selectedBudgets).filter(v => this.selectedBudgets[v]))
      this.key = this.$key()
    },
    changeDefault (v) {
      this.form.defaultUsageReason = v
      this.key = this.$key()
    }
  }
}
</script>
