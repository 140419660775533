<template>
  <base-section :title="$t('company_settings.details_page.gas_stations_section.title')">
    <el-table
      :data.sync="tableData"
      :empty-text="$t('general.empty_table')"
      row-class-name="table-row"
      style="width: 100%"
    >
      <el-table-column
        prop="extId"
        :label="$t('company_settings.details_page.gas_stations_section.table.ext_id')"
        min-width="150"
      >
      </el-table-column>
      <el-table-column
        prop="name"
        :label="$t('company_settings.details_page.gas_stations_section.table.name')"
        min-width="200"
      >
      </el-table-column>
      <el-table-column
        prop="activeFrom"
        :label="$t('company_settings.details_page.gas_stations_section.table.active_from')"
        min-width="100"
      >
        <template slot-scope="{ row: { activeFrom } }">
          {{ activeFrom ? $dateFormat(activeFrom) : '' }}
        </template>
      </el-table-column>
      <el-table-column
        prop="activeTo"
        :label="$t('company_settings.details_page.gas_stations_section.table.active_to')"
        min-width="100"
      >
        <template slot-scope="{ row: { activeTo } }">
          {{ activeTo ? $dateFormat(activeTo) : '' }}
        </template>
      </el-table-column>
    </el-table>
  </base-section>
</template>

<script>
export default {
  name: 'CompanyGasStationsSection',
  data () {
    return {
      tableData: []
    }
  },
  props: {
    ospId: {
      type: String
    }
  },
  computed: {},
  methods: {
    async fetchData () {
      try {
        await this.$api.fuel.getByOwner(this.ospId, {
          onSuccess: this.setData
        })
      } catch (e) {
        console.error(e)
      }
    },
    setData (data) {
      this.tableData = data
    }
  },
  mounted () {
    this.fetchData()
  }
}
</script>

<style lang="scss"></style>
